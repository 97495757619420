import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next";

const FilterInterneExterne = ({ onFilter, activeKey }) => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-left gap-5 mb-2 flex-wrap px-4">
            <span role="button" tabIndex={0} onClick={() => onFilter("")} onKeyDown={() => onFilter("")} className={`filter-tag-rounded ${activeKey === '' ? "active-filter-rounded" : ""}`}>{t("all-events")}</span>
            <span role="button" tabIndex={0} onClick={() => onFilter("interne")} onKeyDown={() => onFilter("interne")} className={`filter-tag-rounded ${activeKey === 'interne' ? "active-filter-rounded" : ""}`}>{t("interne")}</span>
            <span role="button" tabIndex={0} onClick={() => onFilter("externe")} onKeyDown={() => onFilter("externe")} className={`filter-tag-rounded ${activeKey === 'externe' ? "active-filter-rounded" : ""}`}>{t("externe")}</span>
        </div>
    )
}

export default FilterInterneExterne;