import * as React from "react"
import LayoutSENR from "../components/layout/layout-senr"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from 'gatsby'
import SEOComponent from "../components/seo";
import TitlePageComponent from "../components/title-page";
import FilterEvent from "../components/shared/filter-events";
import DateView from "../components/shared/date-view";
import EventList from "../components/event/event-list";
import DayCalendarComponent from "../components/shared/day-calendar";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMoreOutlined, FilterListOutlined } from "@mui/icons-material";
import { createGlobalState } from 'react-hooks-global-state';
import FilterInterneExterne from "../components/shared/filter-interne-externe";
const { useGlobalState } = createGlobalState({ selectedDay: 0 });


const ProgrammationPage = ({ data }) => {
  const { t } = useTranslation();
  const [isDayView, setIsDayView] = React.useState(true);
  const [selectedDay, setSelectedDay] = useGlobalState('selectedDay');
  React.useEffect(() => {
    // Function to check and set the hash value
    const checkHashAndSetSelectedDay = () => {
      const hash = window.location.hash.replace('#', ''); // Remove the '#' from the hash
      const hashValue = parseInt(hash, 10); // Convert the hash to an integer

      // Check if the hash value is between 0 and 5
      if (!isNaN(hashValue) && hashValue >= 0 && hashValue <= 5) {
        setSelectedDay(hashValue);
      }
    };

    // Call the function to check and set the hash value when the component mounts
    checkHashAndSetSelectedDay();

    // Add an event listener to listen for hash changes
    window.addEventListener('hashchange', checkHashAndSetSelectedDay);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('hashchange', checkHashAndSetSelectedDay);
    };
  }, []); // Empty dependency array means this effect runs only once when the component mounts

  
  const setWeekDay = (day) => {
    setSelectedDay(day);
    setIsDayView(true);
  }
  const siteContent = data.siteContent

  const programDay = data.programDay.nodes;
  //get monday of this week
  const dateWeekEvent = new Date(siteContent.dateStartWeek)
  const startHourMin = siteContent.hourStart.split(':')
  const monday = new Date(dateWeekEvent.getFullYear(), dateWeekEvent.getMonth(), dateWeekEvent.getDate() - dateWeekEvent.getDay() + 1, startHourMin[0], startHourMin[1]);
  const startDate = monday;

  // add 6 days to the start date
  const daysInAWeek = 6;
  const endHourMin = siteContent.hourEnd.split(':')
  const endDate = new Date(monday.getFullYear(), monday.getMonth(), (monday.getDate() - monday.getDay() + daysInAWeek), endHourMin[0], endHourMin[1]);
  const events = data.events.nodes.filter(event => 
    new Date(event.timeStart).getTime() >= new Date(siteContent.dateStartWeek).getTime() 
      && (new Date(event.timeStart).getTime() <= endDate.getTime())
    )
  .sort((a, b) => new Date(a.timeStart).getTime() - new Date(b.timeStart).getTime());

  //flat array of typevent in event
  var allTypesEvents = []
  //flat array of routesName in event
  var allRoutesEvents = []
  for (let index = 0; index < events.length; index++) {
    allTypesEvents = allTypesEvents.concat(events[index].typeEvent.split(",").map(type => type.trim()))
    allRoutesEvents = allRoutesEvents.concat(events[index].routeName.split(",").map(route => route.trim()))
  }
  const typeEvent = [...new Set(allTypesEvents.sort())];
  const routeName = [...new Set(allRoutesEvents.sort())];
  const places = [...new Set(events.map(event => event.locationName ?? "Non Specifié").sort())];
  const category = {
    typeEvent,
    routeName,
    places
  }
  const [selectedFilter, setSelectedFilter] = React.useState(category);
  const [selectedTypeEvent, setSelectedTypeEvent] = React.useState("");
  const eventFiltered = events.filter(event => {
    return selectedFilter.typeEvent.some(item => event.typeEvent.includes(item)) &&
    selectedFilter.routeName.some(item => event.routeName.includes(item)) &&
    selectedFilter.places.includes(event.locationName ?? "Non Specifié") &&
    (selectedTypeEvent != "" ? event.interneExterne == selectedTypeEvent : true)
  })

  return (
    <main>
      <LayoutSENR siteContent={siteContent}>
        <SEOComponent title={t("menu_event", { year: siteContent.programYear })} />
        <TitlePageComponent title={t("menu_event", { year: siteContent.programYear })} description={siteContent.program}></TitlePageComponent>
        <div className="container">
        <FilterInterneExterne onFilter={setSelectedTypeEvent} activeKey={selectedTypeEvent}></FilterInterneExterne>
          <div className="p-4">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1a-content" id="panel1a-header">
                <div className="flex items-center">
                  <FilterListOutlined fontSize="large"></FilterListOutlined>
                  <Typography fontSize="lg">Filtre</Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                <FilterEvent category={category} selectedFilter={selectedFilter} setFilter={setSelectedFilter}></FilterEvent>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="lg:hidden">
            <DateView isDayView={isDayView}  startDate={startDate} endDate={endDate} daysInAWeek={daysInAWeek} setIsDayView={setIsDayView} selectedDay={selectedDay} setSelectedDay={setSelectedDay}></DateView>
            <DayCalendarComponent events={eventFiltered} startDate={startDate} endDate={endDate} day={selectedDay} startHour={siteContent.hourStart} endHour={siteContent.hourEnd} programDay={programDay}/>
          </div>

          <div className="hidden lg:block">
            <EventList  events={eventFiltered} startDate={startDate} programDay={programDay} ></EventList>
          </div>

        </div>

      </LayoutSENR>
    </main>
  )
}
export default ProgrammationPage;

export const query = graphql`
  query($language: String!, $programYear: String!) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
          
        }
      }
    }
    programDay: allProgramDayContent(sort: {fields: dateOfDay, order: ASC},) {
      nodes {
      titleDay
      descriptionDay
      typeDay
      dateOfDay
      }
    }
    siteContent(language: {eq: $language}) {
          editionYear
          programYear
          language
          cookieURL
        personalDataURL
        program
        dateStartWeek
        mediaKit
        linkPass
        hourEnd
        hourStart
        banner
    }
    events: allEvent(filter:{language: {eq: $language},
    year: {eq: $programYear}}) {
      nodes {
      timeStart
      timeEnd
      typeEvent
      routeName
      idLiferay
      slug
      interneExterne
      locationName
      isSeveralDate
      typePublic
      typeParcours
      year
      title
       advices
      }
    }
  }
`
